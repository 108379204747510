// Integrates useForm and React MUI TextField
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Controller } from "react-hook-form";
export const FormInputText = ({
  name,
  control,
  label,
  customValidation,
  required = false,
}) => {
  const [errorText, setErrorText] = useState("");

  const validateValue = (value) => {
    if (customValidation) {
      const validationResult = customValidation(value);
      if (validationResult !== true) {
        setErrorText(validationResult);
        return false;
      } else {
        setErrorText("");
        return true;
      }
    }
    return true;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required, validate: validateValue }}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          helperText={error ? errorText || error.message : null}
          error={!!error || !!errorText}
          onChange={onChange}
          value={value ? value : ""}
          fullWidth
          label={label}
          variant="outlined"
          margin="dense"
        />
      )}
    />
  );
};
